import React, { useState, useCallback, useMemo, createContext, useContext } from "react"
import Cookies from "js-cookie"

import { fetchClassesCart, fetchShopifyCart } from "utils/fetchCart"

const CartContext = createContext(null)

export const CartProvider = ({ ...props }) => {
  const [cart, setCart] = useState({
    numCartItems: 0,
    numCartClassesItems: 0,
    numCartProductItems: 0,
  })
  const [shopifyCartToken, setShopifyCartToken] = useState(Cookies.get("shopmbg_cart_token"))

  const getCart = useCallback(async () => {
    const numCartItems = {
      classes: 0,
      products: 0,
    }

    try {
      const cartCount = await fetchClassesCart()
      numCartItems.classes = cartCount
    } catch (error) {
      console.log("fetchClassesCart error:", error)
    }

    if (shopifyCartToken) {
      try {
        const shopifyCart = await fetchShopifyCart(shopifyCartToken)
        numCartItems.products = shopifyCart.item_count
      } catch (error) {
        console.log("fetchShopifyCart error:", error)
      }
    }

    setCart((prevCart) => {
      const nClasses = numCartItems.classes || prevCart.numCartClassesItems
      const nProducts = numCartItems.products || prevCart.numCartProductItems

      return {
        numCartItems: nClasses + nProducts,
        numCartClassesItems: nClasses,
        numCartProductItems: nProducts,
      }
    })
  }, [shopifyCartToken])

  const value = useMemo(
    () => ({
      ...cart,
      getCart,
      shopifyCartToken,
      setShopifyCartToken,
    }),
    [cart, getCart, shopifyCartToken],
  )

  return <CartContext.Provider value={value} {...props} />
}

export const useCart = () => {
  const context = useContext(CartContext)
  if (!context) {
    throw new Error(`useCart must be used within a CartProvider`)
  }
  return context
}
