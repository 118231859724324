/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "pure-react-carousel/dist/react-carousel.es.css"
import "components/layout/fonts.css"

import totalNavHeight from "utils/totalNavHeight"
import { getSegmentDeviceId } from "@mindbodygreen/analytics-tracking-helpers"
import App from "./src/app"

export const wrapPageElement = App

const trackPage = (location, prevLocation) => {
  // make Segment tracking call
  if (window.analytics) {
    // setTimeout to wait for document.title to be available for proper tracking
    window.setTimeout(() => {
      const page = `${process.env.GATSBY_LOCAL_SITE}${location.pathname}`
      const articleId = window.document.querySelector("meta[name='mbg.article_id']")?.content
      const properties = {
        url: page,
        path: location.pathname,
        title: window.document.title,
        ...(articleId && { object_type: "article", object_id: articleId }),
      }
      if (prevLocation) {
        properties.referrer = `${process.env.GATSBY_LOCAL_SITE}${prevLocation.pathname}`
      }

      const event = {
        page: {
          url: page,
        },
        device: { id: getSegmentDeviceId() },
      }
      if (location.pathname === "/") {
        window.analytics.page("Home Page", properties, event)
      } else {
        window.analytics.page(properties, event)
      }
    }, 50)
  }

  try {
    // set up Permutive data layer
    window.pDataLayer = window.pDataLayer || {}
    // push page type and browser language to Permutive data layer
    window.pDataLayer.pageType = location.pathname.includes("/articles") ? "article" : "landing"

    window.pDataLayer.user.browserLanguage =
      window.navigator.userLanguage || window.navigator.language
    // if this is the first location change, push the referral to the Permutive data layer
    if (!prevLocation) {
      // push newsletter referred or email referral to Permutive data layer
      if (
        window.location.href.includes("utm_medium") ||
        window.location.href.includes("utm_campaign") ||
        window.location.href.includes("utm_source")
      ) {
        const pattern = /=newsletter_(\d+)&/
        const campaignId = window.location.href.match(pattern)
        if (campaignId) {
          window.pDataLayer.newsletterReferred = {
            campaignId: campaignId[1],
          }
        } else if (window.location.href.includes("utm_source=Iterable")) {
          window.pDataLayer.referral = { referralType: "email" }
        }
        return
      }

      // push search or social referral to Permutive data layer
      const isSearch = Boolean(document.referrer?.match(/^https:\/\/www\.(google|bing)\.com/))
      if (isSearch) {
        window.pDataLayer.referral = { referralType: "search" }
        return
      }
      const isSocial = Boolean(
        document.referrer?.match(/^https:\/\/www\.(facebook|twitter|instagram)\.com/),
      )
      if (isSocial) {
        window.pDataLayer.referral = { referralType: "social" }
        return
      }

      // push direct of other referral to Permutive data layer
      window.pDataLayer.referral = { referralType: !document.referrer ? "direct" : "other" }
    }
  } catch (err) {
    // ignore
  }
}
export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // Set the scroll behavior to "auto" for all the internal links
  // avoiding the scroll to the top behavior
  try {
    if (
      prevLocation?.pathname !== location?.pathname &&
      prevLocation?.pathname !== `${location?.pathname}/`
    ) {
      window.document.getElementsByTagName("html")[0].style.scrollBehavior = "auto"
    }
  } catch (err) {
    // ignore
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Next Line set the scroll behavior to "smooth" in case it was change
  // it on the onPreRouteUpdate function
  try {
    window.document.getElementsByTagName("html")[0].style.scrollBehavior = "smooth"
  } catch (err) {
    // ignore
  }

  if (!window.segmentSnippetLoaded) {
    window.segmentSnippetLoader(() => {
      trackPage(location, prevLocation)
    })
  } else {
    trackPage(location, prevLocation)
  }

  // support jumping to elements within a page, using a hash "#some-id"
  const { hash } = location
  try {
    const element = hash && document.querySelector(hash)
    if (element) {
      window.scrollTo({
        top: element.offsetTop - totalNavHeight(), // nav bar(s) taken into account
        left: 0,
      })
    }
  } catch (error) {
    console.log(error)
  }
}
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  if (
    prevRouterProps &&
    prevRouterProps.location.pathname === routerProps.location.pathname &&
    routerProps.location.pathname.includes("gift-guide") &&
    prevRouterProps.location.search !== routerProps.location.search
  ) {
    const scrollPos = window.sessionStorage.getItem(`@@scroll|${prevRouterProps.location.key}`)
    if (scrollPos) {
      ;[, document.documentElement.scrollTop] = JSON.parse(scrollPos)
    }

    return false
  }
  window.scroll(0, 0)
  return false
}
