import React from "react"

import { ThemeProvider } from "styled-components"

import appTheme from "./appTheme"
import { LocationProvider } from "store/contexts/location"
import { UserProvider } from "store/contexts/user"
import { CartProvider } from "store/contexts/cart"
import { MainCartProvider } from "store/contexts/main-cart"
import { WebinarPromotionProvider } from "store/contexts/webinar-promotion"
import Layout from "components/layout"

const App = ({ element, props: { location } }) => (
  <LocationProvider initialValue={location}>
    <UserProvider>
      <CartProvider>
        <MainCartProvider>
          <WebinarPromotionProvider>
            <ThemeProvider theme={appTheme}>
              <Layout>{element}</Layout>
            </ThemeProvider>
          </WebinarPromotionProvider>
        </MainCartProvider>
      </CartProvider>
    </UserProvider>
  </LocationProvider>
)

export default App
