export const fetchClassesCart = async () => {
  try {
    const response = await fetch(`/api/account/personalize`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return response.json().cartCount
  } catch (error) {
    console.log(error)
    return 0
  }
}

export const fetchShopifyCart = async (token) => {
  try {
    const response = await fetch(`/api/shopify/cart`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return response.json()
  } catch (error) {
    console.log(error)
    return null
  }
}
