import React, { createContext, useContext, useMemo, useState } from "react"

const WebinarPromotionContext = createContext({
  webinarPromotion: null,
  setWebinarPromotion: () => {},
})

export const WebinarPromotionProvider = ({ children }) => {
  const [webinarPromotion, setWebinarPromotion] = useState(null)

  const memoizedValue = useMemo(
    () => ({
      webinarPromotion,
      setWebinarPromotion,
    }),
    [webinarPromotion],
  )

  return (
    <WebinarPromotionContext.Provider value={memoizedValue}>
      {children}
    </WebinarPromotionContext.Provider>
  )
}

export const useWebinarPromotion = () => {
  const context = useContext(WebinarPromotionContext)

  if (!context) {
    throw new Error(`useWebinarPromotion must be used within a WebinarPromotionProvider`)
  }

  return context
}
