import { css } from "styled-components"
import appTheme from "../appTheme"

/**
 * A function that applies a media query inside a styled component, based on the
 * min-width selector and breakpoints defined in app theme. Used for mobile-first
 * approach to design.
 *
 * https://www.styled-components.com/docs/advanced#media-templates
 */
const minWidth = Object.keys(appTheme.breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${appTheme.breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default minWidth
