import { css } from "styled-components"

/**
 * A helper function for detecting Firefox in CSS.
 */
const ff = (...args) => css`
  @-moz-document url-prefix() {
    ${css(...args)}
  }
`

export default ff
