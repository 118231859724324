import { css } from "styled-components"
import appTheme from "../appTheme"

/**
 * A function that applies a media query inside a styled component, based on the
 * max-width selector and breakpoints defined in app theme. Used for desktop-first
 * approach to design.
 *
 * https://www.styled-components.com/docs/advanced#media-templates
 */
const maxWidth = Object.keys(appTheme.breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${appTheme.breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default maxWidth
