module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mindbodygreen","short_name":"mbg","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/mbg-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dcf4783cc7d913f902ccda11fc00dea5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"fileName":false,"displayName":false,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"0x562SlqISB9oFLcZnMilvKNNqnAWm94","devKey":"0x562SlqISB9oFLcZnMilvKNNqnAWm94","trackPage":false,"delayLoad":true,"delayLoadTime":50},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["Referrer-Policy: strict-origin-when-cross-origin"]},"allPageHeaders":["Link: </fonts/monoline-regular.otf>; rel=preload; as=font; crossorigin;","Link: </fonts/maax-webfont.woff2>; rel=preload; as=font; crossorigin;","Link: </fonts/maax-medium-webfont.woff2>; rel=preload; as=font; crossorigin;","Link: </fonts/abril.woff2>; rel=preload; as=font; crossorigin;"],"mergeSecurityHeaders":true,"mergeLinkHeaders":true,"mergeCachingHeaders":true,"generateMatchPathRewrites":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
