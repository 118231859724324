import React from "react"
import { Script } from "gatsby"

import useJustunoTracking from "hooks/useJustunoTracking"

import Helmet from "react-helmet"
import Footer from "../footer"
import {
  GlobalStyles,
  Container,
  TopAccessibilityContainer,
  TopAccessibilityLink,
  Separator,
} from "./styles"

const Layout = ({ children }) => {
  useJustunoTracking()

  const website = (
    <script data-scheme="WebSite" type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "WebSite",
        "name": "mindbodygreen",
        "url": "https://www.mindbodygreen.com",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "http://www.mindbodygreen.com/search?q={search_term}",
          "query-input": "required name=search_term",
        },
      })}
    </script>
  )
  const org = (
    <script data-scheme="Organization" type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "mindbodygreen",
        "url": "https://www.mindbodygreen.com",
        "logo": {
          "@type": "ImageObject",
          "url":
            "https://mindbodygreen-res.cloudinary.com/image/upload/w_800,h_800/mbg_logo_short-square-2x.png",
          "width": "800",
          "height": "800",
        },
        "sameAs": [
          "https://www.facebook.com/mindbodygreen",
          "https://twitter.com/mindbodygreen",
          "https://www.pinterest.com/mindbodygreen/",
          "https://instagram.com/mindbodygreen",
          "https://www.youtube.com/user/mindbodygreen",
        ],
        "telephone": "+1 877-624-9355",
      })}
    </script>
  )

  return (
    <>
      <Helmet>
        {org}
        {website}
        <script src="https://www.dwin1.com/19038.js" type="text/javascript" defer="defer" />
      </Helmet>
      <Script id="refersion-tracking">
        {`
          (function (w,d,s,u,o,c,e,x) {
            w['TrackingSystemObject']=o;
            e=d.createElement(s);e.async=1;e.src=u;e.onload=c;x=d.getElementsByTagName(s)[0];
            x.parentNode.insertBefore(e, x);
          })(window,document,'script','https://cdn.refersion.com/refersion.js','r',function () {
            r.pubKey = 'pub_ff8495c9ec635f6d582b';
            r.initializeXDLS().then(() => {
              r.launchDefault();
            });
          });
        `}
      </Script>
      <GlobalStyles />
      <TopAccessibilityContainer>
        <TopAccessibilityLink href="#content" aria-label="Skip to content">
          Skip to Content
        </TopAccessibilityLink>
        <Separator />
        <TopAccessibilityLink
          href="/accessibility"
          target="_blank"
          aria-label="Accessibility Statement"
        >
          Accessibility Statement
        </TopAccessibilityLink>
      </TopAccessibilityContainer>
      <Container>
        {children}
        <Footer />
      </Container>
    </>
  )
}

export default Layout
