import appTheme from "../appTheme"

const getViewport = () => {
  if (typeof window === "undefined") {
    return "unknown"
  }

  if (window.innerWidth < appTheme.breakpoints.tablet) {
    return "mobile"
  } else if (window.innerWidth < appTheme.breakpoints.hamburgerLarge) {
    return "tablet"
  } else if (window.innerWidth < appTheme.breakpoints.desktopL) {
    return "desktop"
  } else {
    return "desktopL"
  }
}

export default getViewport
