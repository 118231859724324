import React from "react"
import { Link } from "gatsby"

import { useLocation } from "store/contexts/location"

import {
  Container,
  Top,
  Line,
  LinkSection,
  LinkSectionTitle,
  StyledLink,
  StyledOuterLink,
  Bottom,
  UpperBottom,
  UpperBottomLeft,
  SocialLinks,
  SocialIcon,
  Disclaimer,
  UtilityLinks,
  MBGLogo,
  Copyright,
} from "./styles"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  const { location } = useLocation()
  const isHomePage =
    location.pathname === "/" ||
    location.pathname === "/test-home-page" ||
    location.pathname === "/test/home-page-with-dynamic-content"

  return (
    <Container id="footer">
      <Line />
      <Top>
        <Link to="/" aria-label="Go to main page">
          <MBGLogo />
        </Link>
        <LinkSection>
          <LinkSectionTitle>Shop</LinkSectionTitle>
          <StyledLink to="/user/home">Manage subscriptions & Orders</StyledLink>
          <StyledOuterLink
            href="https://shop.mindbodygreen.com/pages/mindbodygreen-supplements-quiz"
            target="_blank"
            rel="noreferrer"
          >
            Supplements+ Quiz
          </StyledOuterLink>
        </LinkSection>
        <LinkSection>
          <LinkSectionTitle>About</LinkSectionTitle>
          <StyledLink to="/about">About us</StyledLink>
          <StyledLink to="/contact">Contact</StyledLink>
          <StyledLink to="/affiliates">Become an Affiliate</StyledLink>
        </LinkSection>
        <LinkSection>
          <LinkSectionTitle>More</LinkSectionTitle>
          <StyledLink to="/collective">Collective</StyledLink>
          <StyledLink to="/contribute">Write for us</StyledLink>
          <StyledLink to="/jobs">Jobs</StyledLink>
          <StyledLink to="/advertise">Advertise</StyledLink>
        </LinkSection>
      </Top>
      <Bottom>
        <UpperBottom>
          <UpperBottomLeft>
            <SocialLinks>
              <a href="https://www.instagram.com/mindbodygreen" target="_blank" rel="noreferrer">
                <SocialIcon id="social-instagram-footer" width={30} height={30} title="Instagram" />
              </a>
              <a href="https://www.facebook.com/mindbodygreen" target="_blank" rel="noreferrer">
                <SocialIcon id="social-facebook-footer" width={30} height={30} title="Facebook" />
              </a>
              <a href="https://twitter.com/mindbodygreen" target="_blank" rel="noreferrer">
                <SocialIcon id="social-twitter-footer" width={30} height={30} title="Twitter" />
              </a>
              <a href="https://www.pinterest.com/mindbodygreen" target="_blank" rel="noreferrer">
                <SocialIcon id="social-pinterest-footer" width={30} height={30} title="Pinterest" />
              </a>
              <a href="https://www.youtube.com/mindbodygreen" target="_blank" rel="noreferrer">
                <SocialIcon id="social-youtube-footer" width={30} height={30} title="YouTube" />
              </a>
            </SocialLinks>
            <React.Suspense>
              <Copyright>
                &copy; 2009 - {currentYear} mindbodygreen LLC. All rights reserved.
              </Copyright>
            </React.Suspense>
          </UpperBottomLeft>
          <Disclaimer id="footer_disclaimer" isHomePage={isHomePage}>
            *These statements have not been evaluated by the Food and Drug Administration. This
            product is not intended to diagnose, treat, cure or prevent any disease.
          </Disclaimer>
        </UpperBottom>
        <UtilityLinks>
          <Link to="/terms-of-sale">Terms of Sale</Link>
          <Link to="/terms-of-use">Terms of Use</Link>
          <Link to="/disclaimer">Disclaimer</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <a
            href="https://mindbodygreen.zendesk.com/hc/en-us/articles/360044846131-What-is-your-return-policy-"
            target="_blank"
            rel="noreferrer"
          >
            Return Policy
          </a>
          <Link to="/accessibility">Accessibility</Link>
          <Link to="/privacy">Ad Choices</Link>
          <Link to="/data-collection">Data Collection</Link>
          <Link to="/editorial-process">Editorial Process</Link>
        </UtilityLinks>
      </Bottom>
    </Container>
  )
}

export default Footer
