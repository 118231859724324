import React from "react"

const Icon = ({ id, title, ...props }) => (
  <svg {...props}>
    {title && <title>{title}</title>}
    <use href={`/sprite.svg#${id}`} />
  </svg>
)

export default Icon
