import styled, { createGlobalStyle } from "styled-components"
import { ff } from "utils"

/**
 * Font definitions and global resets.
 */
export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;

    /* font smoothing */
    /* https://www.webfwd.co.uk/blog/how-to-smooth-website-fonts-using-css3/ */
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    -moz-osx-font-smoothing: grayscale;

    scroll-behavior: smooth;
  }

  html,
  body,
  body > div {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: ${(props) => props.theme.colors.background.page};
    color: ${(props) => props.theme.colors.text.primary};
    font-family: Maax, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
  }

  // push footer to the bottom
  body > div,
  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
  }

  #gatsby-focus-wrapper {
    flex: 1 0 auto;
  }

  /**
  * Reset default components.
  */

  button {
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  label {
    font-weight: 500;
    color: #222222;
    display: block;
    margin-bottom: 2rem;
  }

  input {
    font-family: inherit;
    min-width: 0;
    width: 100%;
  }

  input[type="text"],
  input[type="email"] {
    background-color: #f0f0f0;
    color: #666666;
    height: 4.5rem;
    line-height: 4.5rem;
    border: 0;
    border-radius: 10rem;
    padding: 0 2rem;
    font-size: 1.5rem;
    font-weight: 500;

    ::placeholder {
      height: inherit;
      line-height: inherit;
    }

    /* firefox */
    ${ff`
      ::placeholder {
        line-height: 0;
      }
    `}
  }

  input[type="submit"] {
    height: 4.8rem;
  }

  strong {
    font-family: inherit;
    font-weight: 500;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    margin: 0;
  }

  blockquote {
    margin: 0;
  }
`

export const Container = styled.main`
  width: 100%;
  max-width: 144rem;
  flex: 1 0 auto;
  margin: 0 auto;
`

export const TopAccessibilityContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 5.2rem;
  z-index: 999;
  transform: translate(-50%, -100%);
  display: flex;
  align-items: center;
  background-color: #d34612;
  color: #fff;
  transition: transform 0.15s;

  &:focus-within {
    transform: translate(-50%, 0%);
  }
`

export const TopAccessibilityLink = styled.a`
  padding: 1rem;
  font-size: 2rem;
  line-height: 3.12rem;
  text-decoration: underline;
`

export const Separator = styled.div`
  width: 0.1rem;
  height: 3rem;
  background-color: #fff;
`
