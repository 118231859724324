import React, { createContext, useContext, useMemo } from "react"

const LocationContext = createContext(null)

export const LocationProvider = ({ initialValue, ...props }) => (
  <LocationContext.Provider
    value={useMemo(() => ({ location: initialValue }), [initialValue])}
    {...props}
  />
)

export const useLocation = () => {
  const context = useContext(LocationContext)
  if (!context) {
    throw new Error(`useLocation must be used within a LocationProvider`)
  }
  return context
}
