import { useCallback, useEffect } from "react"

import useTracking from "hooks/useTracking"
import { identifyUser } from "utils/identifyUser"

const useJustunoTracking = () => {
  const { track } = useTracking()

  const processForm = useCallback(
    (form) => form.reduce((accum, current) => ({ ...accum, [current.name]: current.value }), {}),
    [],
  )

  const impression = useCallback(() => {
    window.juapp("trackFunc", "impression", (email, eventname, promotitle, cmid, options, form) => {
      const formData = processForm(form)

      track("Email Modal Displayed", {
        promotion: promotitle,
        signup_source: formData.Source ?? "",
        list: formData.List ?? "",
        signup_type: "modal",
      })
    })
  }, [processForm])

  const engagement = useCallback(() => {
    window.juapp(
      "trackFunc",
      "engagement",
      async (email, eventname, promotitle, cmid, options, form) => {
        const formData = processForm(form)

        if (!email) {
          return
        }

        try {
          await identifyUser({ user: { email } })
        } catch (err) {
          console.log(err)
        }

        track("Email Registered", {
          promotion: promotitle,
          signup_source: formData.Source,
          list: formData.List ?? "",
          signup_type: "modal",
          email: email ?? "",
        })
      },
    )
  }, [])

  useEffect(() => {
    window.juapp =
      window.juapp ||
      function () {
        ;(window.juapp.q = window.juapp.q || []).push(arguments)
      }

    impression()
    engagement()
  }, [impression, engagement])

  return true
}

export default useJustunoTracking
