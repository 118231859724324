import styled from "styled-components"
import { Link } from "gatsby"

import { minWidth, maxWidth } from "utils"

import Icon from "icons"

export const Container = styled.footer`
  color: ${({ theme }) => theme.colors.text.primary};
`

export const Top = styled.div`
  width: 100%;
  padding: 0 2rem 4rem;
  background-color: #fff;

  ${minWidth.mobile`
    padding-left: 4rem;
    padding-right: 4rem;
  `}

  ${minWidth.hamburgerLarge`
    width: 90%;
    display: flex;
    justify-content: space-between;
  `}

  ${minWidth.rightRail`
    width: 80%;
  `}
`

export const Line = styled.hr`
  height: 0.1rem;
  margin: 2rem 2rem 4rem;
  padding: 0;
  display: block;
  border: 0;
  border-top: 0.1rem solid rgba(34, 34, 34, 0.2);

  ${minWidth.hamburgerLarge`
    margin: 2rem 4rem 4rem;
  `}
`

export const MBGLogo = styled.div`
  font-family: Monoline, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3.16rem;
  ::after {
    content: "Mindbodygreen";
  }
`

export const LinkSection = styled.div`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  text-align: left;

  :first-of-type {
    margin-top: 2rem;
  }

  ${minWidth.hamburgerLarge`
    margin: 0;
  `}
`

export const LinkSectionTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.62rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
`

export const StyledLink = styled(Link)`
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 2.808rem;
`

export const StyledOuterLink = styled.a`
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 2.808rem;
`

export const Bottom = styled.div`
  padding: 6rem 2rem;
  background-color: #f3f3f3;

  ${minWidth.mobile`
    padding-left: 4rem;
    padding-right: 4rem;
  `}
`

export const UpperBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${minWidth.hamburgerLarge`
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  `}
`

export const UpperBottomLeft = styled.div``

export const SocialLinks = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin-right: 2rem;
  }

  ${minWidth.hamburgerLarge`
    justify-content: flex-start;
  `}
`

export const SocialIcon = styled(Icon)`
  max-width: 3rem;
  color: ${({ theme }) => theme.colors.text.primary};
`

export const Copyright = styled.div`
  font-size: 1.4rem;
  line-height: 2.184rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.primary};

  ${minWidth.hamburgerLarge`
    text-align: left;
  `}
`

export const Disclaimer = styled.p`
  display: ${({ isHomePage }) => (isHomePage ? "block" : "none")};
  max-width: 56.7rem;
  height: fit-content;
  margin: 2rem 0;
  padding: 1rem;
  border: 0.1rem solid rgba(34, 34, 34, 0.2);
  font-size: 1.4rem;
  line-height: 2.184rem;
  color: ${({ theme }) => theme.colors.text.primary};

  ${minWidth.hamburgerLarge`
    margin: 0;
  `}
`

export const UtilityLinks = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    margin: 1rem;
    font-size: 1.2rem;
    line-height: 1.872rem;
    color: ${({ theme }) => theme.colors.text.primary};
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.text.primary};
  }

  ${maxWidth.desktop`
    max-width: 144rem;
  `}
`
