import { css } from "styled-components"
import appTheme from "../appTheme"

/**
 * A function that applies a media query inside a styled component, based on the
 * min-width and max-width selector and deviceBreakpoints defined in app theme.
 * Used for mobile-first approach to design.
 *
 * https://www.styled-components.com/docs/advanced#media-templates
 */
const minMaxWidth = Object.keys(appTheme.deviceBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${appTheme.deviceBreakpoints[label].min / 16}em) and (max-width: ${appTheme
        .deviceBreakpoints[label].max / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default minMaxWidth
